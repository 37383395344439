import React from 'react';

const Footer = () => (
  <footer>
    <div>
      <div
        style={{
              marginLeft: `auto`,
              marginRight: `auto`,
              paddingTop: '4rem',
              textAlign: `center`,
              width: `100%`
            }}
        >
        <p><a href='/privacy-policy'>Privacy Policy</a> | <a href='/terms-and-conditions'>Terms and Conditions</a></p>
        <p>
          Copyright © On the Local 2020
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;


import React from 'react';
import { Link, useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";


import "./styles/header.css"


const Header = (location) => {

  const data = useStaticQuery(graphql`
    query LogoQuery {
      logo: file(absolutePath: { regex: "/logoIcon.png/" }) {
        childImageSharp {
          fixed(height: 70, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

    return (
      <header
        style={{
              backgroundColor: `var(--bg)`,
              borderBottom: `1px solid black`,
              boxShadow: `0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)`,
              position: `sticky`,
              top: 0,
              zIndex: 999
            }}
      >
        <div
          style={{
                alignItems: `center`,
                display: `flex`,
                flexDirection: 'column',
                justifyContent: `space-between`,
                marginLeft: `auto`,
                marginRight: `auto`,

                width: `100%`
              }}
           >
          <Link
            style={{
                  alignItems: `center`,
                  color: `var(--secondary)`,
                  display: `flex`,
                  fontFamily: `var(--family-logo)`,
                  fontSize: `2.5rem`,
                  fontStyle: `var(--style-logo)`,
                }}
            to="/"
            >
            <Image
              fixed={data.logo.childImageSharp.fixed}
              alt={`On the Local`}
              style={{
                marginBottom: `0.75rem`,
                marginTop: `0.75rem`,
                minWidth: 50,
              }}
            />
          </Link>
        </div>
      </header>
    )

  }


export default Header;

import React, { useState, useEffect }  from "react";
import CookieConsent from 'react-cookie-consent';

import Header from './header';
import Footer from './footer';

import  "./styles/cookieConsent.css";


const Layout = ({ location, title, children }) => {
  const [cookieConsent, setCookieConsent] = useState(null);
  const [loadedCookieConsent, setLoadedCookieConsent] = useState(false);

  const setScriptHeader = () => {
      setTimeout( () =>{
        setCookieConsent(
          <CookieConsent
            location="bottom"
            style={{
              padding: `$1.25rem 0.75rem}`,
            }}
            buttonStyle={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
            contentStyle={{
              display: 'flex'
            }}
            buttonClasses="cookie-button"
            containerClasses="cookie-container"
            contentClasses="cookie-content"
            buttonText="Accept"
            cookieName="gatsby-gdpr-google-tagmanager"
            sameSite="lax"
            overlay>
            This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. By clicking ‘I Agree’ you are giving your consent to the use of these cookies. To find out more about the cookies we use, see our Privacy Policy.
          </CookieConsent>
        );
      }, 1000);
  }

  useEffect(() => {
    if (typeof window !== `undefined` && !loadedCookieConsent) {
      window.addEventListener('mousemove', setScriptHeader(), {once: true } );
      window.addEventListener('scroll', setScriptHeader(), {once: true } );
      setLoadedCookieConsent(true);
    }
  }, [loadedCookieConsent]);





  return (
    <div>
        {cookieConsent}
        <Header location={location} />
        <main>{children}</main>
        <Footer />

      </div>
  )
};

export default Layout;
